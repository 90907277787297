@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Product+Sans:wght@100;300;500;700;900&display=swap');

/*
BREAKPOINT WIDTHS
xs: '480px',
sm: '576px',
md: '768px',
lg: '992px',
xl: '1200px',
xxl: '1600px', */

:root {
      --white: #FFFFFF;
      --dark: rgba(0,0,0,0.9);
      --medium: rgba(0,0,0,0.1);
      --light: rgba(0,0,0,0.02);

      --pink: #E41882;
      --cyan:rgba(98, 195, 208, 1);
      --blue: #008ED7;
      --light-cyan: rgba(98, 195, 208, 0.7);
}
    
body {
  margin: 0;
  font-size: 16px;
  font-family: 'Product Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,td,th,label,ul,li,button, .ant-alert-content {
      font-family: 'Product Sans', sans-serif !important;
      font-weight:300;
}

p {
      font-size: 16px;
}

td,th,label,ul,li {
      font-size: 14px;
}

h1,h2 {
      font-weight: 700;
      font-family: 'Urbanist', sans-serif;

}

h1 {
      font-size:34px;
}

h2 {
      font-size:24px;
      font-weight: 700;
}

h3 {
      font-size:16px;
      color: var(--cyan);
      margin-bottom:25px;

}
pre {
      font-size:12px;
}

.section {
      padding:50px 25px;
}

@media only screen and (max-width: 992px) {
      .section {
            padding:50px 10px;
      }
    }

#sidebar-left {
      background-color:var(--dark);
      color:var(--white);
      height:100vh;
      font-size:30px;
}

#logo {
      margin-top:-10px;
      margin-bottom:150px;
      cursor:pointer;
}

#main-nav-icons svg {
      margin-bottom:25px !important;
}

#sidebar-left #main-nav-footer-icons {
      /* bottom: 50px;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto; */
      margin-top:400px;
}

#sidebar-left svg {
      display: block;
      margin:auto;
      cursor:pointer;
      color: rgba(255,255,255,0.7);
      transition-duration:0.5s;
}

#sidebar-left svg:hover {

      color: var(--cyan)
}

#sidebar-right {
      background-color:var(--medium);
      color:var(--dark);
      height:100vh;
}

#sidebar-right h2 {
      margin-bottom:50px;
}

#content {
      background-color:var(--light);
      color:var(--dark);
      min-height:100vh;
}



.card {
      background-color: var(--white);
      padding:50px;
      border-radius:2px;
      margin-bottom:25px;

}

.website-listing {
      cursor:pointer;
}

.website-listing .website-name {
      font-weight:700;
      font-size:110%;
      margin-bottom:-5px;
}

.icon-add-button {
      cursor:pointer;
      font-size:28px;
      text-align: right;
      margin-top:8px;
}

.icon-add-button:hover {
      color: var(--cyan)
}

.ant-form-item-label>label {
      font-size: 18px;
  }

  .ant-btn-primary {
      font-weight:800;
      margin-top:25px;
      background-color:var(--pink);
      color: "white";
      padding: 15px 40px;
      border:0;
      font-size:22px;
      height:auto;
      cursor:pointer;
}

.ant-btn-primary:hover {
      background: #31A8BB;
}

.ant-btn-default {
      font-weight:800;
      margin-top:25px;
      padding: 15px 40px;
      border:0;
      font-size:22px;
      height:auto;
      border:1px;
}

.table-search-btn {
      font-size:14px;
      font-weight:500;
      padding: 5px 10px;
}


.table-search-btn-select {
      background-color:#31A8BB;
      color:white;
}

.table-search-btn-reset {
      background-color: #E8E8E8
}

.ant-tabs-tabpane {
      margin:50px 0 25px 0;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--cyan)! important;
  }

  .ant-tabs-tab:hover, .ant-tabs-tab-active:hover, .ant-tabs-tab-btn:hover {
      color: var(--cyan)! important;
  }

  .ant-tabs-ink-bar {
      background: var(--cyan) !important;
   }

   a {
      color: var(--blue) !important
   }

   a:hover {
      color: var(--pink) !important
   }

   .ant-typography-copy, .ant-typography-edit, .ant-typography-expand {
      color: var(--cyan);
  }

  .ant-typography-copy:hover, .ant-typography-edit:hover, .ant-typography-expand:hover {
      color: var(--cyan);
  }

  .clickable {
      cursor:pointer;
  }

  *{
      margin:0;
      padding:0;
  }
  

  .btn-mta {
      background-color:#008ED7;
  }

  .btn-canx {
      background-color:#FF287F;
  }

  .ant-timeline .ant-timeline-item, .ant-timeline .ant-timeline-item p  {
      font-size:16px;
      font-weight:300;
  }
  
  .pagetitle {
      margin-top:50px;
      margin-bottom: 25px;
  }

  .pagetitle p {
      margin-top: -10px;
      font-size: 140%;
      opacity: 0.5;
  }

  .split-bar-wrapper {
      margin:0;
  }

  .split-bar-section {height:10px;}

  .split-bar-fq {
      background:'red' !important;
  }

  .split-bar-bg {
      background:'blue';
  }

  #home-content h2 {
      font-size:1.2rem;
  }

  #header {

      padding:25px 0;
  }

  .ant-drawer-header {
      background-color:var(--dark);
      color:var(--white) !important;
  }

  .ant-drawer-title, .ant-drawer-header-title button {
      color:var(--white) !important;
  }

  .menu-link {
      margin-bottom:10px
  }

  .menu-link button {
      color: var(--blue);
      font-size: 1.2rem;
  }

  .menu-link svg {
      margin-right:15px;
      vertical-align:-3px;
  }

  .icon-spin {
      -webkit-animation: icon-spin 2s infinite linear;
              animation: icon-spin 2s infinite linear;
    }
    
    @-webkit-keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
      }
    }
    
    @keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
      }
    }

    @media only screen and (max-width: 1200px) {
      .card {
            padding:25px;
            margin-bottom:15px;
      }
      .pagetitle {
            margin-top:0;
      }
    }
    .ant-alert {
      border-radius:4px;
    }
    .ant-alert-description {
      margin-top:-10px;
      opacity:0.6;
    }

    .ant-alert-with-description {
      padding-block:10px;
    }

    .ant-alert-success {
      background-color: rgba(198, 239, 214,0.3);
      border: 1px solid rgba(198, 239, 214,0.6);
    }

    .ant-alert-success .ant-alert-icon {
      color:rgba(198, 239, 214,1);
    }

    .ant-alert-info {
      background-color: rgba(190, 209, 251,0.3);
      border: 1px solid rgba(190, 209, 251,0.6);
    }

    .ant-alert-info .ant-alert-icon {
      color:rgba(190, 209, 251,1);
    }

    .ant-alert-error {
      background-color: rgba(228, 24, 130, 0.1);
      border: 1px solid rgba(228, 24, 130, 0.11);
    }

    .ant-alert-error .ant-alert-icon {
      color:rgba(228, 24, 130, 0.2);
    }

    #event-summary svg {
      font-size: 40px;
      margin-top: 3px;
      margin-right:25px;
    }

    .btn-xs {
      margin-top: -5px;
      font-size:14px;
      padding: 5px 15px;
    }
    
    .ant-upload-wrapper .ant-upload-drag .ant-upload {
      padding: 12px 0;
  }

.ant-typography {
      display:inline-table;
      width:400px;
      font-family: 'Product Sans', sans-serif !important;
      font-weight: 300;
      font-size:16px;
}

.ant-typography-edit-content {
      margin-left:25px;
      vertical-align: -5px !important;
      margin-bottom: -5px !important;

}

.navgroup_title {
      margin-top:15px;
      margin-left:10px;
}

.navgroup_link {
      cursor:pointer;
      margin-left:25px;
      font-size: 95%;
      color: var(--blue);
}

.csv-export-btn {
      color: white !important;
}

.csv-export-btn:hover {
      color: white !important;
}

#Header {
      padding: 25px 0;
}

#Footer {
      background-color: var(--white);
      padding: 50px 0;
      min-height: 250px;
}

#Content {
      padding: 125px 0;
}

.clickable:hover {
      cursor:pointer;
}

.pdf-link {
      border:1px dashed #e8e8e8;
      padding:12px 25px 15px 25px;
      font-size:18px;
      margin-top:25px;
}

.pdf-link:hover {
      background:rgba(0,0,0,0.01);
}

.pdf-link-icon {
      position: relative;
      top:5px;
      margin-right:10px;
      font-size:22px;
}